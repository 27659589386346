// @ts-check

import "antd/dist/antd.min.css";

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";

import { ParsePlugin } from "@opendash/plugin-parse";
import { ParseAdminPlugin } from "@opendash/plugin-parse-admin";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { BDEPlugin } from "@opendash/plugin-bde";
//import { OpenwareDataCollection } from "@opendash/plugin-datacollection";

import { ParseAdminConfig } from "./parse.config";
import "./leaflet.config";
import "./highcharts.config";

init("opendash", async (factory) => {
  // Logo:

  // factory.ui.setLogoLink("/");
  // factory.ui.setLogoLinkExternal(true);
  // factory.ui.setLogoImage(require("../assets/xxx.png"));
  // factory.ui.disableFooter();

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:
  factory.registerLanguage("en", "English");
  // factory.registerLanguage("de", "Deutsch");

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async (language) => await import("./translations/parse-custom_en")
  );

  factory.registerTranslationResolver(
    "en",
    "highcharts",
    async (language) => await import("./translations/highcharts_en")
  );

  factory.registerTranslationResolver(
    "en",
    "table",
    async (language) => await import("./translations/table_en")
  );

  factory.registerTranslationResolver(
    "en",
    "app",
    async (language) => await import("./translations/app_en")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(new BDEPlugin());
  //await factory.use(new OpenwareDataCollection());
  await factory.use(
    new ParsePlugin({
      authLdapActive: true,
    })
  );

  await factory.use(new ParseAdminPlugin(ParseAdminConfig));

  await factory.use(
    new OpenwarePlugin({
      secure: true,
      host: "odv3.kompetenzzentrum-siegen.digital",
    })
  );

  // factory.use(new GreisPlugin({}));

  // factory.ui.disableHeader();

  // Widgets

  factory.registerWidget(await import("./widgets/leaflet-demo"));
  factory.registerWidget(await import("./widgets/leaflet-heat"));
  factory.registerWidget(await import("./widgets/hc-kpi"));
  factory.registerWidget(await import("./widgets/hc-kpi-minmax"));
  factory.registerWidget(await import("./widgets/hc-timeseries"));
  factory.registerWidget(await import("./widgets/hc-timeseries-compare"));
  factory.registerWidget(await import("./widgets/hc-pie"));
  factory.registerWidget(await import("./widgets/hc-states"));
  factory.registerWidget(await import("./widgets/hc-states-gantt"));
  factory.registerWidget(await import("./widgets/hc-pattern"));
  factory.registerWidget(await import("./widgets/hc-pattern-heatmap"));
  factory.registerWidget(await import("./widgets/table"));
  factory.registerWidget(await import("./widgets/table-dimension"));
  // factory.registerWidget(await import("./widgets/leaflet-marker"));
  // factory.registerWidget(await import("./widgets/hello-world"));

  // Routing:

  // factory.registerRoute({
  //   path: "/route/to/*",
  //   component: () => import("./path/to/component")
  // });

  // Navigation:

  // factory.registerStaticNavigationGroup({
  //   id: "admin/parse",
  //   label: "openware:admin.label",
  //   order: 20,
  // });

  // factory.registerStaticNavigationItem({
  //   id: "default",
  //   group: "admin/parse",
  //   place: "sidebar",
  //   order: 10,

  //   label: "openware:admin.classes.roles.label",
  //   // icon: "string",
  //   // color: "string",

  //   link: "/",
  //   // event: "string",

  //   routeCondition: "**/*",
  //   activeCondition: "/",
  // });
}).then((app) => {
  console.log("init open.DASH");

  // app.services.DataService.fetchDimensionValuesMultiItemLive(
  //   [
  //     [{ source: "demo@video.de", id: "demo.video.energieverbraucha" }, 0],
  //     [{ source: "demo@video.de", id: "demo.video.energieverbrauchb" }, 0],
  //     [{ source: "demo@video.de", id: "demo.video.energieverbrauchc" }, 0],
  //   ],
  //   {
  //     live: true,
  //     historyType: "absolute",
  //     start: Date.now() - 1000 * 60 * 60 * 24 * 7,
  //     end: Date.now(),
  //   },
  //   (value) => {
  //     console.log(value);
  //   }
  // );
  // // app.services.DataService.fetchDimensionValuesMultiItem(
  // //   [[{ source: "demo@video.de", id: "demo.video.statec" }, 0]],
  // //   { live: true, start: Date.now() - 1000 * 60 * 60, end: Date.now() }
  // // ).then((value) => {
  // //   console.log(value);
  // // });
});
